import { useState, useEffect } from "react";

export const useWindowDimensions = () => {
  const isSSR = typeof window === "undefined";

  const [width, setWidth] = useState(isSSR ? 1000 : window.innerWidth);
  const [height, setHeight] = useState(isSSR ? 600 : window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { height, width };
};
