import React from "react";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

import DisplayComponent from "../components/common/DisplayComponent";
import TilesWrapper from "../components/common/TilesWrapper";

import { BREAKPOINTS } from "../constants/breakpoints";

const AboutUs = () => {
  const { width } = useWindowDimensions();
  const useMoreTiles = width < parseInt(BREAKPOINTS.xl);

  return (
    <div className="relative">
      <div
        className="flex flex-col absolute text-skyscraper left-18% right-18% xs:pt-2 md:pt-4 lg:pt-6 xs:top-30% md:top-35% lg:top-30%"
        id="about"
      >
        <h2 className="text-black font-bold xs:text-3xl md:text-3.5xl lg:text-4xl xs:leading-5 md:leading-6 lg:leading-9">
          O spoločnosti
        </h2>
        <div className="text-charcoalSoul xs:text-base lg:text-2xl xs:leading-5 md:leading-6 lg:leading-7">
          GEODÉZIA LUČENEC, s.r.o. – spoločnosť, ktorá svojim zákazníkom
          poskytuje komplexné geodetické služby s pôsobením v celom
          Banskobystrickom kraji so sídlom v meste Lučenec.
        </div>

        <div className="text-charcoalSoul xs:text-base lg:text-2xl xs:leading-5 md:leading-6 lg:leading-7 xs:mb-3 md:mb-4 lg:mb-6">
          <br />
          Pri našich službách kladieme prioritu na špičkovú kvalitu a čo
          najkratší časový horizont vykonanej práce. Vyhotovené geodetické práce
          overuje autorizovaný geodet a kartograf, člen Komory geodetov a
          kartografov.
        </div>

        <div className="text-charcoalSoul xs:text-base lg:text-2xl xs:leading-5 md:leading-6 lg:leading-7">
          <br />
          Poradíme Vám v oblasti katastra nehnuteľností, geodézie a kartografie
          kedykoľvek bez toho aby ste boli našim klientom.
        </div>

        <div className="text-charcoalSoul xs:text-base lg:text-2xl xs:leading-5 md:leading-6 lg:leading-7">
          <br />
          Pri našej práci používame moderné technické vybavenie a naša znalosť
          operátu katastra nehnuteľností a dlhoročná prax sú zárukou, že nami
          vyhotovené geodetické práce budú bez problémov použiteľné či už na
          právne úkony alebo na iné účely, pre ktoré si ich u nás objednávate.
        </div>
      </div>

      <TilesWrapper>
        <div className="bg-paper w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-paper w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-paper w-full" />
        <div className="bg-titaniumWhite w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-paper w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-titaniumWhite w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-paper w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-paper w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-tropicalForestGreen w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-titaniumWhite w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-tropicalForestGreen w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-titaniumWhite w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-paper w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-paper w-full" />
        <div className="bg-lynxWhite w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <TilesWrapper>
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
        <div className="bg-white w-full" />
      </TilesWrapper>

      <DisplayComponent when={useMoreTiles}>
        <TilesWrapper>
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-paper w-full" />
          <div className="bg-white w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-paper w-full" />
          <div className="bg-white w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-white w-full" />
          <div className="bg-titaniumWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-tropicalForestGreen w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-white w-full" />
          <div className="bg-titaniumWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-tropicalForestGreen w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-paper w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-paper w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
          <div className="bg-lynxWhite w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-paper w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-paper w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-paper w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-titaniumWhite w-full" />
          <div className="bg-paper w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-titaniumWhite w-full" />
          <div className="bg-white w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-paper w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-paper w-full" />
        </TilesWrapper>

        <TilesWrapper>
          <div className="bg-paper w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-white w-full" />
          <div className="bg-paper w-full" />
        </TilesWrapper>
      </DisplayComponent>
    </div>
  );
};

export default AboutUs;
