import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Home = () => {
  const data = useStaticQuery(graphql`
    {
      welcomeImage: file(relativePath: { eq: "welcome2.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <GatsbyImage
      image={data.welcomeImage.childImageSharp.gatsbyImageData}
      alt=""
      title="Welcome image"
    />
  );
};

export default Home;
