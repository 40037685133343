import React from "react";

import ServiceListItem from "./ServiceListItem";

const ServiceList = ({ services }) => {
  return (
    <div className="flex flex-wrap justify-evenly">
      {services?.map((service) => {
        return <ServiceListItem key={service.id} service={service} />;
      })}
    </div>
  );
};

export default ServiceList;
