import React from "react";

const ContactIcon = ({ src, title, alt = "" }) => {
  return (
    <div className="w-6 h-6 mt-3 mr-3 mb-3 ml-0">
      <img alt={alt} title={title} src={src} width={24} height={24} />
    </div>
  );
};

export default ContactIcon;
