import React from "react";

const HiddenLabel = ({ children, htmlFor }) => {
  return (
    <label className="sr-only" htmlFor={htmlFor}>
      {children}
    </label>
  );
};

export default HiddenLabel;
