import React, { useState } from "react";
import ServiceIcon from "./ServiceIcon";
import classnames from "classnames";

import arrow from "../../images/arrow.svg";

const ServiceListItem = ({ service }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={classnames(
        "flex flex-col justify-between items-center text-center",
        "bg-white",
        "p-10 m-2 max-h-96 max-w-75 shadow-2xl",
        "transition-max-height duration-1000 ease-in-out",
        {
          "max-h-195": isExpanded,
        }
      )}
    >
      <div className="w-12 h-12 my-3">
        <ServiceIcon iconName={service?.icon} title={service?.title} />
      </div>
      <div className="text-tropicalForestGreen text-2xl font-bold leading-6 my-3">
        {service?.title}
      </div>
      {service?.price && <div className="text-black">od {service.price}€</div>}
      <div className="flex text-charcoalSoul overflow-hidden overflow-ellipsis my-3 w-full transition-max-height duration-500 ease-in-out">
        {service.description}
      </div>
      <div>
        <input
          type="image"
          src={arrow}
          alt="Arrow icon"
          onClick={() => setIsExpanded(!isExpanded)}
          title="Zobraziť viac"
          width={16}
          height={16}
          className={classnames(
            "transform cursor-pointer duration-500 rotate-90 outline-off",
            {
              "-rotate-90": isExpanded,
            }
          )}
        />
      </div>
    </div>
  );
};

export default ServiceListItem;
