import React from "react";

import addLocation from "../../images/addLocation.svg";
import border from "../../images/border.svg";
import cubes from "../../images/cubes.svg";
import engineering from "../../images/engineering.svg";
import home from "../../images/home.svg";
import map from "../../images/map.svg";
import target from "../../images/target.svg";
import tools from "../../images/tools.svg";

const iconMap = {
  addLocation,
  border,
  cubes,
  engineering,
  home,
  map,
  target,
  tools,
};

const ServiceIcon = ({ iconName, title }) => {
  if (!iconName) {
    return null;
  }

  return (
    <img
      alt="Service icon"
      title={title}
      src={iconMap[iconName]}
      width={48}
      height={48}
    />
  );
};

export default ServiceIcon;
