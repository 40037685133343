import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import ContactIcon from "../components/contact/ContactIcon";
import HiddenLabel from "../components/contact/HiddenLabel";
import Input from "../components/contact/Input";
import Select from "../components/contact/Select";
import TextArea from "../components/contact/TextArea";

import phone from "../images/phone.svg";
import pin from "../images/placeholder.svg";

const Contact = () => {
  const { allServicesJson, contactImage } = useStaticQuery(graphql`{
  contactImage: file(relativePath: {eq: "contact.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allServicesJson {
    nodes {
      id
      title
    }
  }
}
`);

  return (
    <div className="flex flex-row relative overflow-hidden xs:pt-0 md:pt-4 lg:pt-8 xl:pt-height/6">
      <div className="absolute bg-lynxWhite xs:w-full xl:w-screen-65% h-full -z-1 xl:top-1/4 xl:left-35%" />
      <div className="xs:hidden xl:block w-full">
        <GatsbyImage
          image={contactImage.childImageSharp.gatsbyImageData}
          alt=""
          title="Contact us image" />
      </div>
      <div
        className="flex flex-col xs:items-center xl:items-start w-full xs:py-2 xs:px-6 md:p-6 lg:p-8 xl:py-12 xl:px-24"
        id="contact"
      >
        <div className="text-center xs:mb-4 xs:mt-6 md:mb-8 md:mt-12 lg:mb-10 lg:mt-16 xl:mt-20 xl:mb-12">
          <span className="text-black xs:text-3xl md:text-3.5xl lg:text-4xl m-0 leading-10 font-bold">
            Kontaktujte{" "}
          </span>
          <span className="text-tropicalForestGreen xs:text-3xl md:text-3.5xl lg:text-4xl m-0 font-bold leading-10">
            Nás
          </span>
        </div>
        <div className="flex flex-col leading-7 text-left items-center text-charcoalSoul w-full xs:text-lg md:text-xl lg:text-2xl">
          <div className="flex flex-row items-center text-charcoalSoul w-full my-1 mx-0 text-center">
            Geodézia Lučenec, s.r.o.
          </div>
          <div className="flex flex-row items-center text-charcoalSoul w-full my-1 mx-0">
            <ContactIcon src={phone} title="Telefónny kontakt 1" />
            <a
              className="text-tropicalForestGreenDarker"
              href="tel:+421 948 184 213"
            >
              +421 948 184 213
            </a>
          </div>
          <div className="flex flex-row items-center text-charcoalSoul w-full my-1 mx-0">
            <ContactIcon src={phone} title="Telefónny kontakt 2" />
            <a
              className="text-tropicalForestGreenDarker"
              href="tel:+421 918 815 780"
            >
              +421 918 815 780
            </a>
          </div>
          <div className="flex flex-row items-center text-charcoalSoul w-full my-1 mx-0">
            <ContactIcon src={pin} title="Adresa spoločnosti" />
            <span>Martina Rázusa 29, 984 01, Lučenec</span>
          </div>
        </div>

        <div className="text-center xs:mb-4 xs:mt-6 md:mb-8 md:mt-12 lg:mb-10 lg:mt-16 xl:mt-20 xl:mb-12">
          <span className="text-black xs:text-3xl md:text-3.5xl lg:text-4xl m-0 font-bold leading-10">
            Máte záujem o niektorú z &nbsp;
          </span>
          <span className="text-tropicalForestGreen xs:text-3xl md:text-3.5xl lg:text-4xl m-0 font-bold leading-10">
            naších služieb
          </span>
          <span className="text-black xs:text-3xl md:text-3.5xl lg:text-4xl m-0 font-bold leading-10">
            &nbsp;?
          </span>
        </div>
        <form name="contact" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <Input
            name="email"
            id="email"
            title="Email"
            required
            placeholder="Email"
          />
          <Select
            name="sluzba"
            id="sluzba"
            title="Požadovaná služba"
            required
            placeholder="Služba"
            options={allServicesJson.nodes}
          />
          <div className="flex w-full xs:flex-col lg:flex-row">
            <Input
              name="parcelneCislo"
              id="parcelneCislo"
              title="Parcelné číslo"
              placeholder="Parcelné číslo"
            />
            <div className="xs:hidden lg:block w-px mx-3" />
            <Input
              name="katastralneUzemie"
              id="katastralneUzemie"
              title="Katastrálne územie"
              placeholder="Katastrálne územie"
            />
          </div>
          <HiddenLabel htmlFor="sprava">Sprava pre nas</HiddenLabel>
          <TextArea
            id="sprava"
            name="sprava"
            required
            placeholder="Vaša správa pre nás"
          />
          <button
            className="pointer focus:outline-off border-none bg-tropicalForestGreenDarker text-white font-semibold w-full py-3 px-2 my-3 mx-0"
            type="submit"
          >
            Odoslať správu
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
