import React from "react";

const TextArea = ({ id, name, required, placeholer }) => {
  return (
    <textarea
      className="bg-white my-3 mx-0 py-3 px-2 border border-solid border-transparent resize-none w-full h-40 shadow-lg focus:outline-focus"
      id={id}
      name={name}
      required={required}
      placeholder={placeholer}
    />
  );
};

export default TextArea;
