const BREAKPOINTS = {
  xs: "320px",
  sm: "375px",
  md: "425px",
  lg: "768px",
  xl: "1024px",
  xxl: "1440px",
};

module.exports = { BREAKPOINTS };
