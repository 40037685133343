import React from "react";

const Input = ({ name, id, title, required, placeholder }) => {
  return (
    <input
      className="bg-white my-3 mx-0 py-3 px-2 border border-solid border-transparent w-full shadow-lg focus:outline-focus"
      name={name}
      id={id}
      title={title}
      required={required}
      placeholder={placeholder}
    />
  );
};

export default Input;
