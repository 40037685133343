import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import ServiceList from "../components/services/ServiceList";

const Services = () => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      allServicesJson {
        nodes {
          description
          icon
          id
          price
          title
        }
      }
    }
  `);

  const { allServicesJson } = data || {};
  const { nodes } = allServicesJson || {};

  const [leftSideServices, rightSideServices] = new Array(
    Math.ceil(nodes.length / 4)
  )
    .fill()
    .map((_) => nodes.splice(0, 4));

  return (
    <div
      className="flex xs:relative xs:flex-col lg:flex-row xs:mt-88 sm:mt-48 md:mt-60 lg:mt-12 xl:mt-28 xxl:mt-0"
      id="services"
    >
      <div className="bg-tropicalForestGreen text-white text-right xs:pt-4 md:pt-8 lg:pt-12 items-center w-full">
        <h2 className="text-white xs:block lg:hidden xs:text-3xl md:text-3.5xl text-center font-bold leading-8 py-2 px-1 m-0">
          Naše služby
        </h2>
        <h2 className="xs:hidden lg:block xs:absolute lg:relative text-bold m-0 xs:text-3xl md:text-3.5xl lg:text-4xl leading-none p-2">
          Naše
        </h2>
        <div className="flex xs:flex-col xl:flex-row flex-wrap xs:py-10 xs:px-4 lg:py-12 lg:px-6">
          <ServiceList services={leftSideServices} />
        </div>
      </div>
      <div className="bg-white text-black text-left xs:pt-4 md:pt-8 lg:pt-12 items-center w-full">
        <h2 className="xs:hidden lg:block xs:absolute lg:relative text-bold m-0 xs:text-3xl md:text-3.5xl lg:text-4xl leading-none p-2">
          Služby
        </h2>
        <div className="flex xs:flex-col xl:flex-row flex-wrap xs:py-10 xs:px-4 lg:py-12 lg:px-6">
          <ServiceList services={rightSideServices} />
        </div>
      </div>
    </div>
  );
};

export default Services;
