import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import AboutUs from "../sections/AboutUs";
import Contact from "../sections/Contact";
import Home from "../sections/Home";
import Services from "../sections/Services";

const IndexPage = () => (
  <Layout>
    <SEO title="GEOLC - Geodetické služby" />
    <Home />
    <AboutUs />
    <Services />
    <Contact />
  </Layout>
);

export default IndexPage;
