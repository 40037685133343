import React from "react";

const Select = ({ name, id, title, required, placeholder, options }) => {
  return (
    <select
      className="bg-white my-3 mx-0 py-3 px-2 border border-solid border-transparent w-full shadow-lg focus:outline-focus"
      name={name}
      id={id}
      title={title}
      required={required}
      placeholder={placeholder}
    >
      {options.map(({ id, title }) => (
        <option key={id} value={title}>
          {title}
        </option>
      ))}
    </select>
  );
};

export default Select;
